import * as React from "react";
import MetaTags from "react-meta-tags";
import qs from "qs";
import QRCode from "react-qr-code";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 30rem;
  margin: auto;
`;

const Title = styled.h1`
  text-align: center;
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  margin-bottom: 2rem;
  text-align: center;
`;

export const GameInvite = (props) => {
  const search = props.location.search;

  const qrValue = React.useMemo(() => {
    const data = qs.parse(search.replace(/^\?/, ""));
    console.info(data);
    if (!data.link) {
      return "";
    }

    return data.link;
  }, [search]);

  return (
    <>
      <MetaTags>
        <title>Disbate game invitation</title>
      </MetaTags>
      <Wrapper>
        <Title>
          You have been invited
          <br />
          to Disbate game
        </Title>
        <Text>
          Please, scan following QR code with your phone to join the game.
        </Text>
        <QRCode value={qrValue}></QRCode>
      </Wrapper>
    </>
  );
};
