import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { isDesktop, isMobile, isTablet } from 'react-device-detect'

const Stories = () => {

    // current topic
    const currentTopicData = useSelector(state => state.data.currentTopicData)
    // stories
    const storiesData = useSelector(state => state.data.storiesData)

    // расчет ширины блоков свайпера
    const [storiesWidth, setStoriesWidth] = useState(100)
    // ширина сегмента свайпера
    const [storiesItemWidth, setStoriesItemWidth] = useState(100)
    const [deviceType, setDeviceType] = useState(null)
    useEffect(() => {
        if(["isDesktop", "isTablet"].includes(deviceType)===false) {
            setStoriesWidth(window.screen.width)
            setStoriesItemWidth(window.screen.width*0.95)
        } else {
            setStoriesWidth(290)
            setStoriesItemWidth(290)
        }
    }, [deviceType])

    
    // console.log("isMobile", isMobile, "isTablet", isTablet, "isDesktop", isDesktop, "deviceType", deviceType)

    useEffect(() => {
        if(isMobile && !isTablet){
            setDeviceType("isMobile")
        } else if (isMobile && isTablet) {
            setDeviceType("isTablet")
        } else if (!isMobile && !isTablet) {
            setDeviceType("isDesktop")
        }
    }, [])

    return (
        <>
            <div className="storiesContainer" style={["isDesktop", "isTablet"].includes(deviceType)===false ? {width: storiesWidth*0.95, bottom: window.screen.height*0.04} : {width: 290}}>
                
                { currentTopicData && currentTopicData.answers 
                    // static block
                    ?
                    currentTopicData.answers.map((item, index) => (
                    // currentTopicData.answers.filter((item, index) => index===0 ).map((item, index) => (
                        <div key={index} style={index===0 
                            ? {
                                height: 4, 
                                background: "rgba(255, 255, 255, 0.36)", 
                                width: ["isDesktop", "isTablet"].includes(deviceType)===false ? storiesWidth/currentTopicData.answers.length : 280/currentTopicData.answers.length
                                //width: isMobile ? storiesWidth : 300
                            }
                            : {
                                height: 4, 
                                background: "rgba(255, 255, 255, 0.36)", 
                                width: ["isDesktop", "isTablet"].includes(deviceType)===false ? storiesWidth/currentTopicData.answers.length : 280/currentTopicData.answers.length,
                                //width: isMobile ? storiesWidth : 300,
                                marginLeft: 8
                            }
                            }>
                        </div>
                    ))
                    : null
                }
            </div>
            <div className="storiesContainer" style={["isDesktop", "isTablet"].includes(deviceType)===false ? {width: storiesWidth*0.95, bottom: window.screen.height*0.04} : {width: 290}}>
                { storiesData && storiesData[0] && storiesData[0].position !==undefined
                    // dynamic block
                    ?
                    storiesData.map((item, index) => (
                        <div key={index} style={ index===0   
                            ? {
                                height: 4, 
                                background: "#fff", 
                                position: "absolute",
                                left:  ((storiesItemWidth-((currentTopicData.answers.length-1)*8))/currentTopicData.answers.length) * index, 
                                width: (((storiesItemWidth-(currentTopicData.answers.length-1)*8))/currentTopicData.answers.length * (item.position/item.duration)),
                                marginLeft:0
                                // left:  0, 
                                // width: (storiesItemWidth * item.position)/item.duration
                            }
                            : {
                                height: 4, 
                                background: "#fff", 
                                position: "absolute",
                                left: (((storiesItemWidth-((currentTopicData.answers.length-1)*8))/currentTopicData.answers.length) * index + 8*index), 
                                width: (((storiesItemWidth-(currentTopicData.answers.length-1)*8))/currentTopicData.answers.length * (item.position/item.duration)),
                                marginLeft: 0
                                // left: 0, 
                                // width: (storiesItemWidth * item.position)/item.duration
                                
                            }
                            }>
                        </div>
                    ))
                    : null
                }
            </div>
        </> 
    )
}

export default Stories
