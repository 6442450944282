import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import appstore from "../img/badge_appstore.svg";
import googleplay from "../img/badge_googleplay.svg";
// eslint-disable-next-line
import { Header } from "../component/Header";
// api
// eslint-disable-next-line
import { getTopicList } from "../api/api";
// image
import logo from "../img/logo_w_beta_svg.svg";
import playButton from "../img/play-button.png";
import phoneBorder from "../img/phone-border.png";
import { isMobile, isDesktop, isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
// redux
import {
  setAlert,
  setAlertType,
  setBackground,
  setPlayerVisibility,
  setPlayStatus,
} from "../redux/action/uiAction";
import {
  setCurrentTopicData,
  setIndex,
  setMuted,
  setRowIndex,
  setStoriesData,
  setTopicData,
} from "../redux/action/dataAction";
import { useSelector, useDispatch } from "react-redux";
import { translate } from "../function/translate";
// components
import VideoPlayer from "./VideoPlayer";
import AlertComponent from "./AlertComponent";
import Stories from "./Stories";
import Participants from "./Participants";
import InfoBlock from "./InfoBlock";
import Sidebar from "./Sidebar";
import { mockData } from "../api/mock";
import { Carousel } from "react-responsive-carousel";

import { MobileCarousel } from "./MobileCarousel";
import { MobileHomePage } from "./MobileHomepage";
import { HomePageMeta } from "./HomePageMeta";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const Home = () => {
  const { height, width } = useWindowDimensions();
  document.body.style.overflow = "hidden";

  const [duration, setDuration] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const language = useSelector((state) => state.UI.language);
  const playStatus = useSelector((state) => state.UI.playStatus);
  const alert = useSelector((state) => state.UI.alert);
  // позиция стрелки до нажатия
  const [position, setPosition] = useState(0);
  // позиция мышки в момент нажатия
  const [startPosition, setStartPosition] = useState(0);
  // текущая позиция мышки
  const [itemPosition, setItemPosition] = useState(0);
  // предыдущая позиция мышки
  const [prevPosition, setPrevPosition] = useState(0);
  // блокировка движения стрелки
  const [blockArrow, setBlockArrow] = useState(true);
  // count videos per game
  const [participantsPerGame, setParticipantsPerGame] = useState(1);
  const [winId, setWinId] = useState(null);
  const player = useRef(null);
  // const [desktopPlayerVisibility, setDesktopPlayerVisibility] = useState(true)
  const [dimension, setDimension] = useState(null);
  // play block
  const [loop, setLoop] = useState(false);
  // сколько игр воспроизведено
  const [playGame, setPlayGame] = useState(0);
  // параметр для обнуления сторис-данных
  const [storiesParam, setStoriesParam] = useState(0);
  const [app, setApp] = useState("app instaled");
  // не показывать кнопку паузы при скруле кнопкой на десктопе
  const [localPause, setLocalPause] = useState(true);

  // current topic
  const currentTopicData = useSelector((state) => state.data.currentTopicData);
  // stories
  const storiesData = useSelector((state) => state.data.storiesData);
  // все видео
  const topicData = useSelector((state) => state.data.topicData);
  const index = useSelector((state) => state.data.index);
  const rowIndex = useSelector((state) => state.data.rowIndex);

  const playerVisibility = useSelector((state) => state.UI.playerVisibility);

  //add listener
  useEffect(() => {
    // if (isMobile) {
    //   window.addEventListener("touchstart", onTouchStart);
    //   window.addEventListener("touchmove", onTouchMove);
    //   return () => {
    //     window.removeEventListener("touchstart", onTouchStart);
    //     window.removeEventListener("touchmove", onTouchMove);
    //   };
    // }
    dispatch(setIndex(0));
    dispatch(setRowIndex(0));
    setStoriesParam(1);
    dispatch(setStoriesData([]));
  }, [isMobile]);

  useEffect(() => {
    dispatch(setMuted(false));
  }, [isDesktop]);

  const dispatch = useDispatch();

  useEffect(() => {
    getTopicList()
      .then((data) => {
        if (data.data) {
          // console.log("data.data", data.data)
          dispatch(setTopicData(data.data.slice(0, 2)));
          cupFunction(data.data[0]);
          setDimension({
            x: width,
            y: height,
          });
          if (isMobile) {
            dispatch(setIndex(0));
            dispatch(setRowIndex(0));
          } else {
            dispatch(setPlayStatus(false));
          }
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [isMobile]);

  var cupArray = {};
  var sortable = [];

  const cupFunction = (data) => {
    if (data && data.participants && data.participants[0]) {
      // количество участником в игре
      let participantsArray = data.participants.filter((itm, idx) => idx <= 1);
      let participantsArrayLength = participantsArray.length;
      setParticipantsPerGame(participantsArrayLength);

      // setCurrentVideoUrl(data.answers[0].video.url)
      if (data && data.answers) {
        dispatch(setBackground(data.answers[0].video.covers.basic));
      }
      dispatch(setCurrentTopicData(data));

      if (data && data.answers) {
        data.answers.map((item) => {
          const id = item.author.id;
          if (item.score !== 0) {
            cupArray[id] = item.score;
          }
        });

        for (var keys in cupArray) {
          sortable.push([keys, cupArray[keys]]);
        }
        sortable.sort(function (a, b) {
          return a[1] - b[1];
        });

        if (sortable.length) {
          let winId = sortable[sortable.length - 1][0];
          setWinId(winId);
          data.participants.map((i) => {
            sortable.map((itm) => {
              if (i.id === itm[0]) {
                i.userScore = itm[1];
              }
            });
          });
          dispatch(setCurrentTopicData(data));
          dispatch(setRowIndex(0));
        }
      }
    }
  };

  const clickHandler = () => {
    console.log("clickHandler");
    window.open("https://apps.apple.com/ua/app/disbate/id1587363524", "_blank");
  };

  useEffect(() => {
    if (
      currentTopicData &&
      currentTopicData.answers &&
      currentTopicData.answers.length === 1
    ) {
      setLoop(true);
    } else {
      setLoop(false);
    }
  }, [currentTopicData]);

  useEffect(() => {
    if (playGame === 4 && isMobile) {
      setTimeout(() => {
        checkApp();
        dispatch(setPlayStatus(false));
      }, 1000);
    }
  }, [playGame]);

  useEffect(() => {
    if (
      currentTopicData &&
      currentTopicData.answers &&
      rowIndex !== currentTopicData.answers.length - 1 &&
      duration !== null &&
      currentPosition !== null &&
      currentPosition >= duration
    ) {
      if (currentPosition !== null) {
        horizontalSwiper();
      }
    } else if (
      index !== topicData.length - 1 &&
      currentTopicData &&
      currentTopicData.answers &&
      rowIndex === currentTopicData.answers.length - 1 &&
      currentPosition !== 0 &&
      currentPosition === duration
    ) {
      console.log("cycle");
      setStoriesParam(1);
      dispatch(setStoriesData([]));
      verticalSwiper();
    } else if (
      index === topicData.length - 1 &&
      currentTopicData &&
      currentTopicData.answers &&
      rowIndex === currentTopicData.answers.length - 1 &&
      currentPosition !== 0 &&
      // && currentPosition === duration
      currentPosition >= duration
    ) {
      dispatch(setIndex(0));
      dispatch(setRowIndex(0));
      cupFunction(topicData[0]);
      console.log("fullCycle");
      setStoriesParam(1);
      dispatch(setStoriesData([]));
    }
  }, [currentPosition]);

  // swiper
  const horizontalSwiper = () => {
    if (
      currentTopicData &&
      currentTopicData.answers &&
      currentTopicData.answers[rowIndex + 1] &&
      rowIndex <= currentTopicData.answers.length - 1
    ) {
      dispatch(setRowIndex(rowIndex + 1));
    } else if (currentTopicData && currentTopicData.answers) {
      dispatch(setRowIndex(0));
    }
  };

  function verticalSwiper() {
    setStoriesParam(1);
    cupFunction(topicData[index + 1]);
    dispatch(setIndex(index + 1));
  }

  useEffect(() => {
    if (storiesParam === 1) {
      setTimeout(() => {
        dispatch(setStoriesData([]));
      }, 200);
    }
  }, [storiesParam]);

  const updatePlaybackData = (data) => {
    if (storiesParam === 0) {
      setCurrentPosition(data);
      storiesData[rowIndex] = { duration, position: data };
      dispatch(setStoriesData([...storiesData]));
    } else {
      dispatch(setStoriesData([]));
      setStoriesParam(0);
    }
  };

  const closeAlertWindow = () => {
    dispatch(setAlert(false));
    dispatch(setPlayStatus(true));
    // verticalSwiper()
  };

  // if app already installed
  const checkApp = () => {
    dispatch(dispatch(setAlertType(1)));
    dispatch(setAlert(true));
  };

  useEffect(() => {
    setPrevPosition(itemPosition);
    if (!blockArrow) {
      if (
        startPosition >= 0 &&
        itemPosition >= startPosition &&
        itemPosition - startPosition < 20 &&
        itemPosition >= prevPosition
      ) {
        setPosition(itemPosition - startPosition);
      } else if (
        startPosition >= 0 &&
        itemPosition >= startPosition &&
        itemPosition - startPosition < 20 &&
        itemPosition >= prevPosition
      ) {
        setPosition(itemPosition - startPosition);
      } else if (
        itemPosition - startPosition === 20 &&
        itemPosition >= prevPosition
      ) {
        verticalSwiper();
        // setDesktopPlayerVisibility(true)
      } else {
        setBlockArrow(true);
      }

      if (
        startPosition >= 0 &&
        itemPosition >= startPosition &&
        itemPosition - startPosition > 20
      ) {
        dispatch(setPlayStatus(false));
      }
    }

    window.addEventListener("mousemove", move);
    return () => {
      window.removeEventListener("mousemove", move);
    };
  }, [itemPosition]);

  useEffect(() => {
    if (blockArrow) {
      setStartPosition(startPosition);
      setItemPosition(0);
    }
  }, [blockArrow]);

  // desktop

  const move = (e) => {
    setItemPosition(e.pageY);
  };

  // mobile
  const onTouchStart = (e) => {
    console.log("onTouchStart_SCREEN", e.type);
    dispatch(setPlayerVisibility(true));
  };

  const onTouchMove = (e) => {
    console.log("onTouchMove_SCREEN", e.type);
    dispatch(setPlayerVisibility(false));
  };

  const resetPlayerParam = () => {
    dispatch(setIndex(0));
    dispatch(setRowIndex(0));
    cupFunction(topicData[0]);
    setStoriesParam(0);
    dispatch(setStoriesData([]));
    dispatch(setPlayStatus(false));
    setPlayGame(0);
  };

  const playHandler = () => {
    dispatch(setPlayStatus(!playStatus));
    if (playStatus) {
      dispatch(setPlayerVisibility(false));
    } else {
      dispatch(setPlayerVisibility(true));
    }
  };

  if (isMobile) {
    return (
      <>
        {!!alert && (
          <AlertComponent closeAlertWindow={closeAlertWindow} app={app} />
        )}
        <HomePageMeta />
        <MobileHomePage />
      </>
    );
  }

  return (
    <div className="main" style={{ height: "100vh" }}>
      <HomePageMeta />

      {false &&
      isMobile &&
      dimension &&
      topicData &&
      topicData[index]?.answers[rowIndex]?.video ? (
        <div
          className="playButtonMobile"
          style={{
            top: window.innerHeight / 2 - 15,
            left: window.innerWidth / 2 - 15,
            zIndex: 500,
          }}
        >
          {!playStatus && localPause ? (
            <img src={playButton} alt="play" />
          ) : null}
        </div>
      ) : null}

      {false && isMobile && dimension && topicData && topicData[index] ? (
        <div>
          <div style={{ borderWidth: 1, borderColor: "red" }}>
            
          </div>
        </div>
      ) : null}

      {isMobile && <MobileCarousel />}
      {/* {isMobile &&
      dimension &&
      topicData &&
      topicData[index]?.answers[rowIndex]?.video ? (
        <div
          style={{
            position: "absolute",
            height: dimension.y,
            width: dimension.x,
            // // background: "#ddd",
            top: 0,
            // left: 0,
            // zIndex: 99,
            // display: playerVisibility ? "none" : "block"
            // zIndex: playerVisibility ? 311 : 302
          }}
        >
          <VideoPlayer
            player={player}
            topicData={topicData}
            rowIndex={rowIndex}
            index={index}
            updatePlaybackData={updatePlaybackData}
            setDuration={setDuration}
            playStatus={playStatus}
            loop={loop}
          />
        </div>
      ) : null} */}

      {/* {isMobile &&
      dimension &&
      topicData &&
      topicData[index]?.answers[rowIndex]?.video ? (
        <div
          style={{
            position: "absolute",
            height: dimension.y,
            width: dimension.x,
            background: "none",
            top: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <Carousel
            axis="vertical"
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            swipeable={true}
            useKeyboardArrows={false}
            onClickItem={() => {
              dispatch(setPlayStatus(!playStatus));
            }}
            onChange={(selectedIndex) => {
              dispatch(setIndex(selectedIndex));
              cupFunction(topicData[selectedIndex]);
              dispatch(setRowIndex(0));
              setPlayGame(selectedIndex);
              dispatch(setPlayerVisibility(true));
              dispatch(setStoriesData([]));
              setStoriesParam(0);
            }}
            selectedItem={index}
            swipeScrollTolerance={10}
            preventMovementUntilSwipeScrollTolerance
          >
            <div
              style={{
                ...backgroundStyle,
                backgroundImage: `url(${topicData[0]?.answers[rowIndex]?.video?.covers?.basic})`,
                opacity: playerVisibility ? 0 : 1,
              }}
            />
            <div
              style={{
                ...backgroundStyle,
                backgroundImage: `url(${topicData[1]?.answers[rowIndex]?.video?.covers?.basic})`,
                opacity: playerVisibility ? 0 : 1,
              }}
            />
          </Carousel>
        </div>
      ) : null} */}

      <div className="row">
        <div className="static-white-page_container">
          {!isMobile ? (
            <div className="col-md-6">
              <div className="leftContainer">
                <div className="leftBlock">
                  <div className="topHomeContainer">
                    <div className="logoContainer">
                      <img src={logo} alt="logo" className="logo" />
                    </div>
                    <span className="title1">
                      {translate("Mobile app that", language)}
                    </span>
                    <div
                      style={{
                        background: "#CBDBFC",
                        borderRadius: 14,
                        height: 40,
                        marginTop: 4,
                        marginBottom: 4,
                        width: 335,
                      }}
                    >
                      <span
                        className="title1"
                        style={{ color: "black", paddingLeft: 8 }}
                      >
                        {translate("solves conflicts online", language)}
                      </span>
                    </div>
                    <span className="title1">
                      {translate("in few clicks", language)}.
                    </span>
                  </div>

                  <div className="bottomHomeContainer">
                    <div className="homeLinkBlock">
                      <div className="itemLeftBlock">
                        <Link
                          onClick={resetPlayerParam}
                          to="/aboutus"
                          className="bottom-link"
                        >
                          {translate("About us", language)}
                        </Link>
                        <Link
                          onClick={resetPlayerParam}
                          to="/ourmission"
                          className="bottom-link"
                        >
                          {translate("Our mission", language)}
                        </Link>
                      </div>
                      <div className="itemLeftBlock">
                        <Link
                          onClick={resetPlayerParam}
                          to="/features"
                          className="bottom-link"
                        >
                          {translate("Features", language)}
                        </Link>
                        <Link
                          onClick={resetPlayerParam}
                          to="/contact"
                          className="bottom-link"
                        >
                          {translate("Contacts", language)}
                        </Link>
                      </div>
                    </div>
                    <div style={{ marginTop: 12 }}>
                      <span className="title2">
                        {translate("Download_now", language)}
                      </span>
                    </div>
                    <div className="storeContainer">
                      <button
                        className="btn"
                        type="button"
                        onClick={clickHandler}
                        style={{ padding: 0 }}
                      >
                        <img
                          src={appstore}
                          alt="appstorelogo"
                          className="appStoreLogo"
                        />
                      </button>
                      <div style={{ padding: 2, opacity: 0.5 }}>
                        <img
                          src={googleplay}
                          alt="googleplay"
                          className="gpLogo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className="col-md-6"
            style={
              isMobile
                ? { padding: 0, position: "inherit" }
                : { padding: 0, position: "relative" }
            }
          >
            <div className="rightContainer">
              {!isMobile &&
              dimension &&
              topicData &&
              topicData[index]?.answers[rowIndex]?.video ? (
                <div
                  style={{
                    position: "absolute",
                    overflow: "hidden",
                    borderRadius: 40,
                  }}
                >
                  <Carousel
                    axis="vertical"
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    useKeyboardArrows={false}
                    onChange={(selectedIndex) => {
                      // console.log("selectedIndex", selectedIndex)
                      dispatch(setIndex(selectedIndex));
                      cupFunction(topicData[selectedIndex]);
                      dispatch(setRowIndex(0));
                      setPlayGame(selectedIndex);
                      dispatch(setPlayStatus(true));
                    }}
                    selectedItem={index}
                    height={680}
                    width={338}
                  >
                    <div
                      style={{
                        ...backgroundMobileStyle,
                        backgroundImage: `url(${topicData[0]?.answers[rowIndex]?.video?.covers?.basic})`,
                      }}
                    >
                      {index === 0 ? (
                        <VideoPlayer
                          player={player}
                          topicData={topicData}
                          rowIndex={rowIndex}
                          index={0}
                          updatePlaybackData={updatePlaybackData}
                          setDuration={setDuration}
                          playStatus={playStatus}
                          loop={loop}
                        />
                      ) : null}
                    </div>
                    <div
                      style={{
                        ...backgroundMobileStyle,
                        backgroundImage: `url(${topicData[1]?.answers[rowIndex]?.video?.covers?.basic})`,
                      }}
                    >
                      {index === 1 ? (
                        <VideoPlayer
                          player={player}
                          topicData={topicData}
                          rowIndex={rowIndex}
                          index={1}
                          updatePlaybackData={updatePlaybackData}
                          setDuration={setDuration}
                          playStatus={playStatus}
                          loop={loop}
                        />
                      ) : null}
                    </div>
                  </Carousel>
                  <div className="specContainer">
                    <div className="statusBar"></div>
                    {topicData && topicData[index] ? (
                      <Sidebar
                        clickHandler={clickHandler}
                        topicData={topicData}
                        index={index}
                      />
                    ) : null}
                    <div className="bottomBlock">
                      {topicData && topicData[index] ? (
                        <Participants
                          topicData={topicData}
                          index={index}
                          clickHandler={clickHandler}
                          winId={winId}
                          participantsPerGame={participantsPerGame}
                        />
                      ) : null}
                      <InfoBlock topicData={topicData} index={index} />
                    </div>
                    <Stories />
                    <button
                      className="btn playButton"
                      type="button"
                      onClick={playHandler}
                    ></button>
                  </div>
                  {!playStatus && localPause ? (
                    <div style={{ position: "absolute", top: 340, left: 168 }}>
                      <img src={playButton} alt="play" />
                    </div>
                  ) : null}
                </div>
              ) : null}
              {isMobile && !isTablet ? (
                <div style={{ zIndex: 140 }}>
                  <Stories />
                </div>
              ) : (
                <div
                  className="phoneContainer"
                  style={
                    isDesktop || isTablet
                      ? { backgroundImage: `url(${phoneBorder})` }
                      : { backgroundImage: `none` }
                  }
                ></div>
              )}
              {isMobile && !isTablet ? (
                <div className="storeContainerMobile">
                  <div style={{ marginTop: 0 }}>
                    <img src={logo} alt="logo" className="logoMobile" />
                  </div>
                  <span className="mobileText" style={{ marginTop: 10 }}>
                    {translate("Discuss_argue_win", language)}.
                  </span>
                </div>
              ) : null}
              {isDesktop || isMobile ? (
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  onMouseDown={() =>
                    index === 0 ? verticalSwiper() : resetPlayerParam()
                  }
                >
                  <div className="swiperContainer">
                    <div className="btn swiperButton" style={{ zIndex: "500" }}>
                      <div
                        className="arrowContainer"
                        style={
                          index === 4
                            ? { display: "none" }
                            : { top: position + 10, animation: setItemPosition }
                        }
                      ></div>
                    </div>
                    <span
                      className="swipeText"
                      style={
                        index !== 4 ? { display: "block" } : { display: "none" }
                      }
                    >
                      Click for
                    </span>
                    <span
                      className="swipeText"
                      style={
                        index !== 4
                          ? { display: "block", marginTop: 15 }
                          : { display: "none" }
                      }
                    >
                      the next
                    </span>
                    <span
                      className="swipeText"
                      style={
                        index !== 4
                          ? { display: "block", marginTop: 30 }
                          : { display: "none" }
                      }
                    >
                      video
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const backgroundStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  // position: "absolute",
  height: window.innerHeight,
  width: window.innerWidth,
};

const backgroundMobileStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: 680,
  width: 228,
  borderRadius: 40,
};
