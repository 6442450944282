import * as React from "react";
import { use100vh } from "react-div-100vh";
import { MobileCarousel } from "../MobileCarousel";
import { PlayerContext, PlayerContextValue } from "./PlayerContext";
import styles from "./styles.module.scss";

export const MobileHomePage = () => {
  const [isPlaying, setIsPlayingState] = React.useState<boolean>(false);
  const [isSwiping, setIsSwipingState] = React.useState<boolean>(false);
  const fullHeight = use100vh();
  const setIsSwiping = (value: boolean) => {
    console.info(`set is swiping ${value.toString()}`);
    setIsSwipingState(value);
  };

  const setIsPlaying = (value: boolean) => {
    console.info(`set is playing ${value.toString()}`);
    setIsPlayingState(value);
  };

  return (
    <PlayerContext.Provider
      value={{ isPlaying, isSwiping, setIsSwiping, setIsPlaying }}
    >
      <div>
        {/* @ts-ignore */}
        <MobileCarousel />
      </div>
    </PlayerContext.Provider>
  );
};
