import React from "react";
import { useSelector } from "react-redux";
import { GameType } from "../../../types/GameType";
import styles from './styles.module.scss';
import userIco from "../../../img/user-ico.png";
import whistleIco from "../../../img/whistle-ico.png";

const InfoBlock = ({ game }: { game: GameType }) => {
  // current topic
  const currentTopicData = game;

  function ucFirst(str: string) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  }

  return (
    <div className={styles.infoBlock}>
      {currentTopicData ? (
        <span className="title4white">{ucFirst(currentTopicData.type)}</span>
      ) : // <span className="title4white">debate</span>
      null}
      <div className="gameName">
        {currentTopicData ? (
          <span className="title3">{currentTopicData.description}</span>
        ) : // <span className="title3">Chocolate</span>
        null}
      </div>
      {currentTopicData &&
      currentTopicData.hashtags &&
      currentTopicData.hashtags[0] ? (
        <div className="hashTagLine">
          <span className="title3" style={{ marginTop: 3 }}>
            #
          </span>
          <div className="hashData">
            <span className="title4white" style={{ marginLeft: 6 }}>
              {currentTopicData.hashtags[0]}
            </span>
          </div>
        </div>
      ) : null}
      <div className="lastLine">
        <img src={userIco} alt="user" className="userIco" />
        {currentTopicData && currentTopicData.author.username ? (
          <span className="title4white">
            {currentTopicData.author.username}
          </span>
        ) : // <span className="title4white">5148351</span>
        null}
        {currentTopicData && currentTopicData.judge ? (
          <>
            <img src={whistleIco} alt="judge" className="whisleIco" />
            <span className="title4white">
              {currentTopicData.judge.username}
            </span>
            {/* <span className="title4white">First Judge</span>     */}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default InfoBlock;
