import React, {useEffect} from "react"
import MetaTags from 'react-meta-tags'
import { translate } from '../function/translate'
import { useSelector } from 'react-redux'
import logoBetaBlue from '../img/logo-w-beta-blue.svg'
import logoAppStore from '../img/logoAppStore.svg'
import logoGP from '../img/logoGP.svg'
import RightContentAbout from '../img/RightContentAbout.png'


import { Link } from 'react-router-dom'


const DesktopAboutUs = () => {
    
    document.body.style.overflow='hidden'


    const language = useSelector(state => state.UI.language)

    const goToStoreButton = (param) => {
        // TODO
        console.log("goToStoreButton")
    }

    return (
        <div className="static-white-page">
            <MetaTags>
                <title>{translate("About Us", language)} | Disbate</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Disbate" />
                <meta property="og:image" content="../../public/logo.png" />
            </MetaTags>
            
            <div className="row">
                <div className="col-md-12">
                    <div className="static-white-page_header">
                        <Link to="/">
                            <img src={logoBetaBlue} alt="disbate" className="static-white-page_logo"/>
                        </Link>
                        <div>
                            <Link to="/aboutus" className="title2" style={{color: "#000", textDecoration: 'none'}}>{translate("About us", language)}</Link>
                            <Link to="/ourmission" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Our mission", language)}</Link>
                            <Link to="/features" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Features", language)}</Link>
                            <Link to="/contact" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Contacts", language)}</Link>
                            <div style={{
                                position: "absolute",
                                top: 69,
                                left: 695,
                                width: 72,
                                height: 2,
                                background: "#2D69E0"
                            }}>
                            </div>
                        </div>
                    </div>
                    <div className="static-white-page_container">
                        <div className="static-white-page_block">
                            <div>
                                <span className="title0">The solution</span>
                            </div>
                            <div style={{marginTop: 64}}>
                                <span className="title1" style={{color: "#000000"}}>Disbate is the mobile app that<br/> solves conflicts online in few<br/> clicks</span>
                            </div>
                            <div style={{marginTop: 36}}>
                                <span className="title2" style={{color: "#000000", fontWeight: "500"}}>Disbate provides process aimed at establishing the truth.<br/> Fast, free and simple method of conducting a dispute, debate<br/> or discussion in the form of a mobile application or a <br/>computer software.</span>
                            </div>
                            {/* <div style={{marginTop: 36}}>
                                <span className="title2" style={{color: "#7A8599", fontWeight: "500"}}>The technology is protected by US Provisional Patent № 63233208</span>
                            </div> */}
                            <div style={{marginTop: 36}}>
                                <span className="title2" style={{color: "#7A8599", fontWeight: "500"}}>Born in the USA, the land of the Free and the home of the Brave.</span>
                            </div>
                        </div>
                        <div className="static-white-page_block" style={{display: "flex", alignItems: "flex-end"}}>
                            <img src={RightContentAbout} alt="disbate" style={{height: 535, width: 496}}/>  
                        </div>
                    </div>
                    <div className="static-white-page_footer">
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <span><Link  className="title4gray" to="/privacy">Our Privacy Policy</Link> or <Link className="title4gray" to="/terms">Our Terms Of Use</Link></span>
                            <span className="title4gray" style={{marginTop: 4}}>© 2021 Disbate</span>
                        </div>

                        <div>
                            <a style={{borderWidth: 0, background: "#fff"}} target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                                <img src={logoAppStore} alt="disbate" className="static-white-page_logo_bottom"/>
                            </a>
                            <img src={logoGP} alt="disbate" className="static-white-page_logo_bottom" style={{width: 116, marginLeft: 12, opacity: .5}}/>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}

export default DesktopAboutUs