import "./index.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import MainContainer from "./Container/MainContainer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GameInvite } from "./component/GameInvite";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/gameInvite" component={GameInvite}></Route>
        <Route>
          <Provider store={store}>
            <MainContainer />
          </Provider>
        </Route>
      </Switch>
    </Router>
  );
}
