import * as React from "react";
import styles from "./styles.module.scss";
import { VerticalSlider } from "./VerticalSlider";
import { HorizontalSlider } from "./HorizontalSlider";
import { connect } from "react-redux";
import { GameType } from "../../types/GameType";
import { GameAnswer } from "./GameAnswer";
import { PlayerContext } from "../MobileHomepage/PlayerContext";
import { Stories } from "./Stories";

interface State {
  x: number;
  y: number;
}

interface Props {
  topicData: GameType[];
}

class MobileCarouselComponent extends React.PureComponent<Props, State> {
  state: State = {
    x: 0,
    y: 0,
  };

  onHorizontalIndexCange = (index: number) => {
    this.setState({ x: index });
  };

  onVerticalIndexChange = (index: number) => {
    this.setState({ x: 0, y: index });
  };

  setNext = (): boolean => {
    const currentGame = this.props.topicData[this.state.y];
    const nextAnserIndex =
      this.state.x >= currentGame.answers.length - 1 ? 0 : this.state.x + 1;
    let nextGameIndex = this.state.y;
    if (nextAnserIndex === 0) {
      if (nextGameIndex + 1 > this.props.topicData.length - 1) {
        return false;
      } else {
        nextGameIndex += 1;
      }
    }

    this.setState({
      x: nextAnserIndex,
      y: nextGameIndex,
    });

    return true;
  };

  render() {
    return (
      <>
        <VerticalSlider
          activeIndex={this.state.y}
          onSelect={this.onVerticalIndexChange}
        >
          {this.props.topicData.map((game, verticalIndex) => (
            <HorizontalSlider
              activeIndex={this.state.x}
              isActive={this.state.y === verticalIndex}
              onSelect={this.onHorizontalIndexCange}
              key={game.id}
            >
              {game.answers.map((answer, horizontalIndex) => (
                <GameAnswer
                  setNext={this.setNext}
                  index={`${verticalIndex}-${horizontalIndex}`}
                  shouldShowPlayer={
                    ([
                      this.state.x - 1,
                      this.state.x,
                      this.state.x + 1,
                    ].includes(horizontalIndex) &&
                      verticalIndex === this.state.y) ||
                    (horizontalIndex === 0 &&
                      [
                        this.state.y - 1,
                        this.state.y,
                        this.state.y + 1,
                      ].includes(verticalIndex))
                  }
                  game={game}
                  key={answer.id}
                  answer={answer}
                  isActive={
                    this.state.y === verticalIndex &&
                    this.state.x === horizontalIndex
                  }
                />
              ))}
            </HorizontalSlider>
          ))}
        </VerticalSlider>
        <Stories
          x={this.state.x}
          y={this.state.y}
          games={this.props.topicData}
        />
      </>
    );
  }
}

export const MobileCarousel = connect((state: any) => ({
  topicData: state.data.topicData as GameType[],
}))(MobileCarouselComponent);
