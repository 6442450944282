import React, { useRef } from "react"
import MetaTags from 'react-meta-tags'
import { translate } from '../function/translate'
import { useSelector } from 'react-redux'
// import close from '../img/close-white.png'
import logoBetaBlue from '../img/logo-w-beta-blue.svg'
import logoAppStore from '../img/logoAppStore.svg'
import logoGP from '../img/logoGP.svg'
import RightContentOurMissin from '../img/RightContentOurMissin.png'
import ReactPlayer from 'react-player'

import url from '../video/mission.mp4'


import { Link } from 'react-router-dom'


const DesktopOurMission = () => {

    document.body.style.overflow='hidden'

    const player = useRef()

    const language = useSelector(state => state.UI.language)

    const goToStoreButton = (param) => {
        // TODO
        console.log("goToStoreButton")
    }

    return (
        <div className="static-white-page">
            <MetaTags>
                <title>{translate("Our mission", language)} | Disbate</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Disbate" />
                <meta property="og:image" content="../../public/logo.png" />
            </MetaTags>
            <div className="row">
                <div className="col-md-12">
                <div className="static-white-page_header">
                    <Link to="/">
                        <img src={logoBetaBlue} alt="disbate" className="static-white-page_logo"/>
                    </Link>
                    <div>
                        <Link to="/aboutus" className="title2" style={{color: "#7A8599", textDecoration: 'none'}}>{translate("About us", language)}</Link>
                        <Link to="/ourmission" className="title2" style={{color: "#000", marginLeft: 48, textDecoration: 'none'}}>{translate("Our mission", language)}</Link>
                        <Link to="/features" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Features", language)}</Link>
                        <Link to="/contact" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Contacts", language)}</Link>
                        <div style={{
                                 position: "absolute",
                                 top: 69,
                                 left: 817,
                                 width: 96,
                                 height: 2,
                                 background: "#2D69E0"
                            }}>
                        </div>
                    </div>
                </div>
                
                <div className="static-white-page_container">
                    <div className="static-white-page_block">
                        <div>
                            <span className="title0">Our mission</span>
                        </div>
                        <div style={{marginTop: 64}}>
                            <span className="title1" style={{color: "#2D69E0"}}>Stop the violence</span>
                        </div>
                        <div style={{marginTop: 8}}>
                            <span className="title2" style={{color: "#000000", fontWeight: "500"}}>People tend to force their point of view using violence due to a lack of argument. We believe it is wrong and can be changed by fair and public dispute or debate.
</span>
                        </div>
                        <div style={{marginTop: 48}}>
                            <span className="title1" style={{color: "#2D69E0"}}>Reveal the truth</span>
                        </div>
                        <div style={{marginTop: 8}}>
                            <span className="title2" style={{color: "#000000", fontWeight: "500"}}>We aim to provide the process to establish the truth. It can be the truth that is born in dispute. Or the truth that was hidden from the public but is to be told. We help to make it fast and easy.
</span>
                        </div>
                        <div style={{marginTop: 48}}>
                            <span className="title1" style={{color: "#2D69E0"}}>Solve conflicts</span>
                        </div>
                        <div style={{marginTop: 8}}>
                            <span className="title2" style={{color: "#000000", fontWeight: "500"}}>People tend to avoid or freeze conflicts, thus overloading their minds with unsolved issues. We provide instruments to fix it up and free up your head.
</span>
                        </div>
                    </div>
                    <div className="static-white-page_block" 
                    style={{
                        display: "flex", 
                        alignItems: "flex-end", 
                        }}>
                        {/* <img src={RightContentOurMissin} alt="disbate" style={{height: 535, width: 496}}/>   */}
                        <ReactPlayer 
                            url={url}
                            source={url}
                            width={496}
                            height={496}
                            playing={true}
                            loop={false} 
                            ref={player}
                            resizeMode="cover"
                        />
                    </div>
                </div>

                <div className="static-white-page_footer">
                    <div style={{display: "flex", flexDirection: "column"}}>
                    <span><Link  className="title4gray" to="/privacy">Our Privacy Policy</Link> or <Link className="title4gray" to="/terms">Our Terms Of Use</Link></span>
                        <span className="title4gray" style={{marginTop: 4}}>© 2021 Disbate</span>
                    </div>

                    <div>
                        <a style={{borderWidth: 0, background: "#fff"}} target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                            <img src={logoAppStore} alt="disbate" className="static-white-page_logo_bottom"/>
                        </a>
                        <img src={logoGP} alt="disbate" className="static-white-page_logo_bottom" style={{width: 116, marginLeft: 12, opacity: .5}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default DesktopOurMission
