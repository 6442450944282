import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLanguage } from '../redux/action/uiAction'

export const HeaderMobile = () => {
    
    const dispatch = useDispatch()

    const language = useSelector(state => state.UI.language)

    const languageArray = ['gb', 'de', 'ru', 'es', 'it']

    const [ dropdown, setDropdown ] = useState('dropdown')
    const [ dropdownMenu, setDropdownMenu ] = useState('dropdown-menu')
    const [ menuStatus, setMenuStatus ] = useState(false)

    const menuHandler = () => {  
        if(menuStatus === false){           
            setMenuStatus(true)             
            setDropdown('dropdown show')
            setDropdownMenu('dropdown-menu show')
        } else {            
            setMenuStatus(false)                
            setDropdown('dropdown')
            setDropdownMenu('dropdown-menu')
        }
    }

    const changeLanguage = param => {
        dispatch(setLanguage(param))
        setDropdown('dropdown')
        setDropdownMenu('dropdown-menu')
    }

    const languageString = data => {
        switch(data) {
            case "gb": return "English";
            case "de": return "German";
            case "ru": return "Русский";
            case "es": return "Spain";
            case "it": return "Italy";
            default: return "English";
        }
    }
    return (
        <div className="header" style={{marginTop: 20}}>
        <div className="languageContainer">
            <div className={dropdown}>
                {/* <button 
                    className="btn btn-secondary dropdown-toggle" 
                    type="button" id="dropdownMenuButton" 
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    onClick={menuHandler}
                >
               
                <span style={{color: "#000"}}>{languageString(language)}</span>
                </button> */}
                    <ul className={dropdownMenu} aria-labelledby="dropdownMenuButton" style={{paddingLeft: 0, paddingTop: 0}}> 
                        {languageArray && languageArray.filter(itm => itm !==language).map((item, index) => (
                            <li key={index}>
                                <a className="dropdown-item" href="#" onClick={() => changeLanguage(item)}>
                                    {languageString(item)}
                                </a>
                                
                            </li>
                        ))}
                    </ul>
            </div>
        </div>
    </div>
    )
}