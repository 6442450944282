import * as React from "react";
import { GameType } from "../../../types/GameType";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { isPropertyAccessExpression } from "typescript";
import Pubsub from "pubsub-js";

interface Props {
  x: number;
  y: number;
  games: GameType[];
}

export const Stories = (props: Props) => {
  const [state, setState] = React.useState(0);

  React.useEffect(() => {
    setState(0);
  }, [props.x, props.y, setState]);

  React.useEffect(() => {
    const token = Pubsub.subscribe(
      "updateProgress",
      (message: string, percent: number) => {
        if (percent === 0 || percent === 100) return;
        setState(percent);
      }
    );

    return () => {
      Pubsub.unsubscribe(token);
    };
  }, []);

  if (props.games.length === 0) return null;

  const currentGame = props.games[props.y];

  return (
    <div className={styles.storiesWrapper}>
      {currentGame.answers.map((answer, index) => (
        <div
          className={classNames(styles.storyIndicator, {
            [styles.storyIndicatorActive]: index < props.x,
          })}
        >
          {index === props.x && (
            <div
              className={styles.currentStoryIndicator}
              style={{ width: `${state}%` }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
