import React from "react"
import MetaTags from 'react-meta-tags'
import { translate } from '../function/translate'
import { useSelector } from 'react-redux'
import logoBetaBlue from '../img/logo-w-beta-blue.svg'
import logoAppStore from '../img/logoAppStore.svg'
import logoGP from '../img/logoGP.svg'
import map from '../img/map.png'
import close from '../img/close-white.png'
import email from '../img/email.png'

import { Link } from 'react-router-dom'
import { isMobile } from "react-device-detect"


const DesktopContacts = () => {

    const language = useSelector(state => state.UI.language)

    document.body.style.overflow='hidden'

    const goToStoreButton = (param) => {
        // TODO
        console.log("goToStoreButton")
    }

    // if(isMobile) {
    //     return (
    //         <div className="static-page">
    //         {   isMobile && 
    //             <Link to="/" className="closeContainer"  style={{position: "absolute", left: 20, top: 30}} >
    //                 <img src={close} alt="disbate" className="closeIcon"/>
    //             </Link>
    //         }
    //         <MetaTags>
    //             <title>{translate("Contacts", language)} | Disbate</title>
    //             <meta name="description" content="" />
    //             <meta property="og:title" content="Contacts | Disbate" />
    //             <meta property="og:image" content="../img/disbate-logo.png" />
    //         </MetaTags>
    //         <div className="row">
    //             <div className="col">
    //             <div className="static-header">
    //                 <h1>{translate("Contacts", language)}</h1>
    //             </div>
    //             <div className="static-container">
    //                 <p><b>Mindcraft Inc., </b>USA, Wilmington, Delaware</p>
    //                 <p>3 Germay Dr,</p>
    //                 <p>Unit 4 #2316</p>
    //                 <p>Wilmington DE 19804</p>
    //                 <p><b>Email: </b>feedback@disbate.com</p>
    //             </div>
    //             </div>
    //         </div>
    //     </div>
    //     )
    // }

    return (
        <div className="static-white-page">
            <MetaTags>
                <title>{translate("Contacts", language)} | Disbate</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Disbate" />
                <meta property="og:image" content="../../public/logo.png" />
            </MetaTags>
            
            
            
            {isMobile ?
                <div className="static-page">
                    {   isMobile && 
                        <Link to="/" className="closeContainer"  style={{position: "absolute", left: 20, top: 30}} >
                            <img src={close} alt="disbate" className="closeIcon"/>
                        </Link>
                    }
                    <MetaTags>
                        <title>{translate("Contacts", language)} | Disbate</title>
                        <meta name="description" content="" />
                        <meta property="og:title" content="Contacts | Disbate" />
                        <meta property="og:image" content="../img/disbate-logo.png" />
                    </MetaTags>
                    <div className="row">
                        <div className="col">
                        <div className="static-header">
                            <h1>{translate("Contacts", language)}</h1>
                        </div>
                        <div className="static-container">
                            <p><b>Mindcraft Inc., </b>USA, Wilmington, Delaware</p>
                            <p>3 Germay Dr,</p>
                            <p>Unit 4 #2316</p>
                            <p>Wilmington DE 19804</p>
                            <p><b>Email: </b>feedback@disbate.com</p>
                        </div>
                        </div>
                    </div>
                </div>
                : <div className="row">
                        <div className="col-md-12">
                        <div className="static-white-page_header">
                            <Link to="/">
                                <img src={logoBetaBlue} alt="disbate" className="static-white-page_logo"/>
                            </Link>
                            <div>
                                <Link to="/aboutus" className="title2" style={{color: "#7A8599", textDecoration: 'none'}}>{translate("About us", language)}</Link>
                                <Link to="/ourmission" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Our mission", language)}</Link>
                                <Link to="/features" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Features", language)}</Link>
                                <Link to="/contact" className="title2" style={{color: "#000", marginLeft: 48, textDecoration: 'none'}}>{translate("Contacts", language)}</Link>
                                <div style={{
                                        position: "absolute",
                                        top: 69,
                                        right: 15,
                                        width: 72,
                                        height: 2,
                                        background: "#2D69E0"
                                    }}>
                                </div>
                            </div>
                        </div>
                        <section className="overlay" aria-hidden="true">
                            <div>
                                <div className="static-white-page_container" style={{height: window.screen.height*0.33}}>
                                    <div className="static-white-page_block" style={{justifyContent: "flex-start", marginTop: 64, background: "#fff"}}>
                                        <div>
                                            <span className="title0">Contacts</span>
                                        </div>
                                        <div style={{marginTop: 64}}>
                                            <span className="title1" style={{color: "#000000"}}>Mindcraft Inc.,<br/> USA, Wilmington, Delaware</span>
                                        </div>
                                        <div style={{marginTop: 8}}>
                                            <span className="title2" style={{color: "#000000", fontWeight: "500"}}>3 Germay Dr,<br/> Unit 4 #2316<br/> Wilmington DE 19804</span>
                                        </div>
                                    </div>
                                    <div className="static-white-page_block" style={{display: "flex", justifyContent: "flex-start", marginTop: 180}}>
                                        <div>
                                            <span className="title1" style={{color: "#000000"}}>We’ll be glad to hear from you</span>
                                        </div>
                                        <div style={{marginTop: 8}}>
                                            {/* <span className="title1" style={{color: "#2D69E0"}}>feedback@disbate.com</span> */}
                                            <img src={email} alt="disbate" style={{ width: 337, height: 34}}/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    width: 1136, 
                                    height: 389, 
                                    display: "flex", 
                                    alignItems: 'center', 
                                    position: "absolute", 
                                    top: 300}}>
                                    <img src={map} alt="disbate" style={{width: 1136, height: 214, marginTop: 0}}/>  
                                </div>
                            </div>
                        </section>
                        <div className="static-white-page_footer">
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <span><Link  className="title4gray" to="/privacy">Our Privacy Policy</Link> or <Link className="title4gray" to="/terms">Our Terms Of Use</Link></span>
                                <span className="title4gray" style={{marginTop: 4}}>© 2021 Disbate</span>
                            </div>

                            <div>
                                <a style={{borderWidth: 0, background: "#fff"}} target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                                    <img src={logoAppStore} alt="disbate" className="static-white-page_logo_bottom"/>
                                </a>
                                <img src={logoGP} alt="disbate" className="static-white-page_logo_bottom" style={{width: 116, marginLeft: 12, opacity: .5}}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            
            






    </div>
    )
}

export default DesktopContacts