import * as React from "react";
import ReactPlayer from "react-player";
import { GameAnswerType } from "../../../types/GameAnswerType";
import { PlayerContext } from "../../MobileHomepage/PlayerContext";
import like from "../../../img/heart_red.svg";
import comment from "../../../img/comment.png";
import share from "../../../img/share.png";
import playButton from "../../../img/play-button.png";
import styles from "./styles.module.scss";
import InfoBlock from "./InfoBlock";
import Participants from "./Participants";
import { GameType } from "../../../types/GameType";
import classnames from "classnames";
import Pubsub from "pubsub-js";

export const GameAnswer = (props: {
  game: GameType;
  answer: GameAnswerType;
  isActive: boolean;
  shouldShowPlayer: boolean;
  index: string;
  setNext(): boolean;
}) => {
  const { isActive } = props;

  const [isReadyToPlay, setIsReadyToPlay] = React.useState(false);

  const context = React.useContext(PlayerContext);

  const onOpenApp = React.useCallback(() => {
    window.open("https://apps.apple.com/ua/app/disbate/id1587363524", "_blank");
  }, []);

  const playerRef = React.useRef<ReactPlayer | null>();

  const isPlaying = context.isPlaying && !context.isSwiping && props.isActive;

  React.useEffect(() => {
    if (isActive) {
      playerRef.current?.seekTo(0);
      if (isPlaying) {
        context.setIsPlaying(false);
        context.setIsPlaying(true);
      }
    }
  }, [isActive]);

  const onPlatyPauseClick = React.useCallback(() => {
    context.setIsPlaying(!context.isPlaying);
  }, [context]);

  const onEnd = React.useCallback(() => {
    context.setIsSwiping(true);
    const isSetted = props.setNext();
    if (!isSetted) {
      context.setIsSwiping(false);
      return;
    }
    const onTransitionEnd = () => {
      window.removeEventListener("transitionend", onTransitionEnd);
      context.setIsSwiping(false);
    };

    window.addEventListener("transitionend", onTransitionEnd);
  }, []);

  const onProgress = React.useCallback(
    (progress: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
    }) => {
      Pubsub.publish("updateProgress", Math.round(progress.played * 100));
    },
    []
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.playerWrapper}>
        {props.shouldShowPlayer && (
          <>
            {/* @ts-ignore */}
            <ReactPlayer
              url={props.answer.video?.url}
              width="100%"
              height="100%"
              playing={isPlaying}
              playsinline
              style={{ zIndex: 1, position: "relative" }}
              config={{
                file: {
                  attributes: {
                    poster: props.answer?.video?.covers?.basic,
                  },
                },
              }}
              onProgress={onProgress}
              progressInterval={100}
              onEnded={onEnd}
              onReady={() => setIsReadyToPlay(true)}
              ref={(player) => (playerRef.current = player)}
            />
          </>
        )}

        <img
          src={props.answer?.video?.covers?.basic}
          className={styles.videoCover}
        />
      </div>

      <div className={styles.videoOverlay}>
        <div className={styles.playPauseButton} onClick={onPlatyPauseClick}>
          {!context.isPlaying && (
            <div className={styles.playButtonWrapper}>
              <img src={playButton} />
            </div>
          )}
          {context.isPlaying && !isReadyToPlay && (
            <div className={styles.playButtonWrapper}>loading</div>
          )}
        </div>

        <div className={classnames("icoBlockMoble", styles.infoElement)}>
          <a
            className="btn icoItem"
            target="blank"
            href="https://apps.apple.com/ua/app/disbate/id1587363524"
          >
            <img
              src={like}
              alt="like"
              className="icoItem"
              style={{ width: 20, height: 18.3 }}
            />
            <span className="icoText">{props.answer.author.stats.likes}</span>
          </a>
          <a
            className="btn icoItem"
            target="blank"
            href="https://apps.apple.com/ua/app/disbate/id1587363524"
          >
            <img
              src={comment}
              alt="comment"
              className="icoItem"
              style={{ width: 20.5, height: 20 }}
            />
            <span className="icoText">
              {props.answer.author.stats.comments}
            </span>
          </a>
          <a
            className="btn icoItem"
            target="blank"
            href="https://apps.apple.com/ua/app/disbate/id1587363524"
          >
            <img
              src={share}
              alt="share"
              className="icoItem"
              style={{ width: 20, height: 17 }}
            />
            <span className="icoText">{props.answer.author.stats.shares}</span>
          </a>
        </div>
        <div className={classnames(styles.bottomInfo, styles.infoElement)}>
          <div className={styles.infoLeftCol}>
            <Participants
              game={props.game}
              answer={props.answer}
              clickHandler={onOpenApp}
            />
            <InfoBlock game={props.game} />
          </div>
          <div className={styles.infoRightCol}>
            <a
              className={classnames("btn", styles.getInButton)}
              target="blank"
              href="https://apps.apple.com/ua/app/disbate/id1587363524"
            >
              <span className="title3" style={{ fontSize: 13 }}>
                Get app
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
