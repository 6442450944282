import React from "react";
import { GameAnswerType } from "../../../types/GameAnswerType";
import { GameType } from "../../../types/GameType";
import styles from "./styles.module.scss";
import avatar from "../../../img/avatar.png";
import cup from "../../../img/cup.png";
import classnames from "classnames";

const participantsPerGame = 3;

interface ParticipantsProps {
  game: GameType;
  answer: GameAnswerType;
  clickHandler(): void;
}

const avatarIco = {};

const counter = {};

const Participants = ({ game, answer, clickHandler }: ParticipantsProps) => {
  const winId = React.useMemo(() => {
    const sorted = game.participants.sort((a, b) => b.userScore - a.userScore);
    return sorted[0].id;
  }, [game]);

  return (
    <div className={classnames("avatarRow", styles.avatarRow)}>
      {game.participants.slice(0, participantsPerGame).map((author, index) => (
        <a
          key={index}
          target="blank"
          href="https://apps.apple.com/ua/app/disbate/id1587363524"
          className={styles.avatarWrapper}
        >
          <div className={styles.avatarContainer}>
            <img
              src={
                author.avatar["160x160"] !== ""
                  ? author.avatar["160x160"]
                  : avatar
              }
              alt="avatar"
              className={styles.avatar}
            />
          </div>
          {!!author.userScore && (
            <div className={styles.score}>{author.userScore}</div>
          )}
        </a>
      ))}
      <div className="avatarRate">
        <a
          className="rateRow"
          target="blank"
          href="https://apps.apple.com/ua/app/disbate/id1587363524"
        >
          <span className="title4white">Rate</span>
          <span className="title4gray" style={{ marginLeft: 4 }}>
            +5
          </span>
        </a>
      </div>
    </div>
  );
};

export default Participants;
