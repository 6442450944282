import * as React from "react";
import { use100vh } from "react-div-100vh";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import styles from "./styles.module.scss";

interface Props {
  children: JSX.Element[];
  onSelect(activeIndex: number): void;
  activeIndex: number;
}

export const VerticalSlider = ({ children, onSelect, activeIndex }: Props) => {
  const fullHeight = use100vh() as number;

  const count = React.useMemo(() => children.length, [children]);

  const onDragEnd = React.useCallback(
    (event: DraggableEvent, data: DraggableData) => {
      const delta = data.lastY + activeIndex * fullHeight;
      const deltaTreshold = fullHeight / 5;

      if (Math.abs(delta) < deltaTreshold) return;

      let next: number;
      if (delta > 0) {
        next = Math.max(activeIndex - 1, 0);
      } else {
        next = Math.min(activeIndex + 1, count - 1);
      }

      const target = event.currentTarget;

      const onTransitionEnd = () => {
        target?.removeEventListener("transitionend", onTransitionEnd);
      };

      target?.addEventListener("transitionend", onTransitionEnd);
      onSelect(next);
    },
    
    [onSelect, fullHeight, activeIndex, count]
  );

  return (
    <>
      <div style={{ height: fullHeight }} className={styles.verticalSlider}>
        {/* @ts-ignore */}
        <Draggable
          axis="y"
          position={{ x: 0, y: activeIndex * -1 * fullHeight }}
          defaultClassNameDragging={styles.draggableInnerDraggable}
          onStop={onDragEnd}
        >
          <div
            className={styles.draggableInner}
            style={{ height: `${100 * count}%` }}
          >
            {children}
          </div>
        </Draggable>
      </div>
    </>
  );
};
