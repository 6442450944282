import React from 'react'
import {isMobile } from 'react-device-detect'

import like from '../img/heart_red.svg'
import comment from '../img/comment.png'
import share from '../img/share.png'

const Sidebar = ({clickHandler, topicData, index}) => {
    return (
       <div className="icoBlock" style={isMobile ? {left: window.screen.width*0.77} : {left: 345} }>
            <a className="btn icoItem" target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                <img src={like} alt="like" className="icoItem" style={{width: 20, height: 18.3}}/>
                <span className="icoText">{topicData[index].stats.likes}</span>
            </a>
            <a className="btn icoItem" target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                <img src={comment} alt="comment" className="icoItem" style={{width: 20.5, height: 20}}/>
                <span className="icoText">{topicData[index].stats.comments}</span>
            </a>
            <a className="btn icoItem" target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                <img src={share} alt="share" className="icoItem" style={{width: 20, height: 17}}/>
                <span className="icoText">{topicData[index].stats.shares}</span>
            </a>

            {!isMobile ?
                <a className="btn itemButton" target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                    <span className="title3" style={{fontSize: 13}}>Get app</span>
                </a>
                : null
            }

            
        </div>
    )
}

export default Sidebar
