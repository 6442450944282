import React from "react"
import MetaTags from 'react-meta-tags'
import { translate } from '../function/translate'
import { useSelector } from 'react-redux'
import logoBetaBlue from '../img/logo-w-beta-blue.svg'
import logoAppStore from '../img/logoAppStore.svg'
import logoGP from '../img/logoGP.svg'
import close from '../img/close-white.png'

import { Link } from 'react-router-dom'
import { isMobile } from "react-device-detect"
import TefmsOfUse from './TefmsOfUse'


const DesktopTerms = () => {

    const language = useSelector(state => state.UI.language)
    document.body.style.overflow='auto'

    return (
        <div className="static-white-page">
            <MetaTags>
                <title>{translate("Terms of use", language)} | Disbate</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Disbate" />
                <meta property="og:image" content="../../public/logo.png" />
            </MetaTags>
            
            {!isMobile ?
                    <div className="row">
                    <div className="col-md-12">
                    <div className="static-white-page_header">
                        <Link to="/">
                            <img src={logoBetaBlue} alt="disbate" className="static-white-page_logo"/>
                        </Link>
                        <div>
                            <Link to="/aboutus" className="title2" style={{color: "#7A8599", textDecoration: 'none'}}>{translate("About us", language)}</Link>
                            <Link to="/ourmission" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Our mission", language)}</Link>
                            <Link to="/features" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Features", language)}</Link>
                            <Link to="/contact" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Contacts", language)}</Link>
                        </div>
                    </div>
                    <div className="static-white-page_container">
                        <div className="static-white-page_block" style={{justifyContent: "flex-start"}}>
                            <div style={{marginTop: 64}}>
                                <span className="title0">Terms of use</span>
                            </div>
                            <div className="static-container" style={{width: 1136, padding: 0}}>

                                <TefmsOfUse />
                                <p>Via Email: <a href="privacy@disbate.com">privacy@disbate.com</a></p>
                                <div style={{height: 50}}></div>
                                <div className="static-white-page_footer">
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <span><Link  className="title4gray" style={{textDecoration: 'none'}} to="/privacy">Our Privacy Policy</Link> or <Link className="title4gray" style={{textDecoration: 'none'}} to="/terms">Our Terms Of Use</Link></span>
                                        <span className="title4gray" style={{marginTop: 4}}>© 2021 Disbate</span>
                                    </div>

                                <div>
                                    <a style={{borderWidth: 0, background: "#fff"}} target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                                        <img src={logoAppStore} alt="disbate" className="static-white-page_logo_bottom"/>
                                    </a>
                                    <img src={logoGP} alt="disbate" className="static-white-page_logo_bottom" style={{width: 116, marginLeft: 12, opacity: .5}}/>
                                </div>
                                </div>
                        </div>
                        </div>
                    </div>

                    
                </div>
            </div>
            : <div className="row">
                 <Link to="/" className="closeContainer"  style={{position: "absolute", left: 20, top: 30}} >
                    <img src={close} alt="disbate" className="closeIcon"/>
                </Link>
                <div className="col">
                <div className="static-header">
                    <h1>{translate("Terms_Of_Use", language)}</h1>   
                </div>
                    <div className="static-container">

                    <TefmsOfUse />

                    <p>Via Email: <a href="privacy@disbate.com">privacy@disbate.com</a></p>
                        <div style={{height: 50}}></div>
                    </div>
                </div>
            </div>
            }
    </div>
    )
}

export default DesktopTerms