import React, { useState, useRef, useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import { Home } from "../component/Home";
import Eula from "../component/Eula";
import NotFound from "../component/NotFound";
import { useDispatch, useSelector } from "react-redux";
import menuWhite from "../img/menu-w.svg";
import menuBlack from "../img/close.svg";
import { translate } from "../function/translate";
import { HeaderMobile } from "../component/HeaderMobile";
import ReactPlayer from "react-player";
import url from "../video/1.mp4";
import DesktopAboutUs from "../component/DesktopAboutAs";
import DesktopOurMission from "../component/DesktopOurMission";
import DesktopFeatures from "../component/DesktopFeatures";
import DesktopContacts from "../component/DesktopContacts";
import DesktopPrivacy from "../component/DesktopPrivacy";
import DesktopTerms from "../component/DesktopTerms";
import { setPlayStatus } from "../redux/action/uiAction";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { GameInvite } from "../component/GameInvite";

const MainContainer = () => {
  const player = useRef(null);
  const dispatch = useDispatch();
  const { playStatus, language } = useSelector((state) => state.UI);

  // menu
  const [menuVisibility, setMenuVisibility] = useState(false);
  const menuHandler = () => {
    setMenuVisibility(!menuVisibility);
    // dispatch(setPlayStatus(!playStatus))
  };

  const [deviceType, setDeviceType] = useState(null);
  // console.log("isMobile", isMobile, "isTablet", isTablet, "isDesktop", isDesktop, "deviceType", deviceType)

  useEffect(() => {
    if (isMobile && !isTablet) {
      setDeviceType("isMobile");
    } else if (isMobile && isTablet) {
      setDeviceType("isTablet");
    } else if (!isMobile && !isTablet) {
      setDeviceType("isDesktop");
    }
  }, []);

  return (
    <div
      className="body"
      style={
        ["isDesktop", "isTablet"].includes(deviceType)
          ? { backgroundColor: "#396BCC" }
          : { backgroundColor: "none", height: 0 }
      }
    >
      {/* {!isMobile && background !==null && <img src={background} alt="disbate" className="background" style={backgroundStyle}/>}  */}
      {["isDesktop", "isTablet"].includes(deviceType) ? (
        <div style={backgroundStyle} className="background">
          <ReactPlayer
            ref={player}
            url={url}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                },
              },
              video: {
                objectFit: "cover",
              },
            }}
            width={"100vw"}
            height={"100vh"}
            playing={true}
            loop={true}
            progressInterval={50}
            controls={false}
            playsinline={true}
            muted
          />
        </div>
      ) : null}

      <button className="btn mobileMenu" onClick={menuHandler}>
        {menuVisibility ? (
          <img src={menuBlack} alt="disbate-mobile" className="mobileIco" />
        ) : (
          <img src={menuWhite} alt="disbate-mobile" className="mobileIco" />
        )}
      </button>
      <Router>
        {menuVisibility ? (
          <div className="menuContainer">
            <ul>
              <li style={{ fontWeight: "bold" }}>
                {translate("Company", language)}
              </li>
              <li onClick={() => setMenuVisibility(!menuVisibility)}>
                <Link to="/contact" className="menuLink">
                  {" "}
                  - {translate("Contacts", language)}
                </Link>
              </li>
              <li style={{ fontWeight: "bold" }}>
                {translate("Downloads", language)}
              </li>
              <li onClick={() => setMenuVisibility(!menuVisibility)}>
                <a
                  target="blank"
                  href="https://apps.apple.com/us/app/disbate/id1587363524"
                  className="menuLink"
                >
                  {" "}
                  - {translate("App Store", language)}
                </a>
              </li>
              {/* <li onClick={() => setMenuVisibility(!menuVisibility)}><a target="blank" href="https://play.google.com/store" className="menuLink"> - {translate("Google Play", language)}</a></li>  */}
              <li style={{ fontWeight: "bold" }}>
                {translate("Legal", language)}
              </li>
              <li onClick={() => setMenuVisibility(!menuVisibility)}>
                <Link to="/terms" className="menuLink">
                  {" "}
                  - {translate("Terms_Of_Use", language)}
                </Link>
              </li>
              <li onClick={() => setMenuVisibility(!menuVisibility)}>
                <Link to="/privacy" className="menuLink">
                  {" "}
                  - {translate("Privacy_Policy", language)}
                </Link>
              </li>
              {/* <li onClick={() => setMenuVisibility(!menuVisibility)}><Link to="/eula" className="menuLink"> - {translate("Eula", language)}</Link></li>  */}
            </ul>
            <span style={{ marginLeft: 20 }}>@ 2021 Disbate</span>
            <div className="header-mobile">
              <HeaderMobile />
            </div>
          </div>
        ) : null}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/eula" component={Eula}></Route>
          {/* <Route path="/contacts" component={Contact}></Route> */}
          <Route path="/aboutus" component={DesktopAboutUs}></Route>
          <Route path="/ourmission" component={DesktopOurMission}></Route>
          <Route path="/features" component={DesktopFeatures}></Route>
          <Route path="/contact" component={DesktopContacts}></Route>
          <Route path="/privacy" component={DesktopPrivacy}></Route>
          <Route path="/terms" component={DesktopTerms}></Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default MainContainer;

const backgroundStyle = {
  position: "absolute",
  height: "100vh",
  width: "100vw",
  // backgroundColor: "red"
};
