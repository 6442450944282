import * as React from "react";

export interface PlayerContextValue {
  isPlaying: boolean;
  isSwiping: boolean;
  setIsPlaying(value: boolean): void;
  setIsSwiping(value: boolean): void;
}

export const PlayerContext = React.createContext<PlayerContextValue>({
  isPlaying: true,
  isSwiping: false,
  setIsPlaying: () => {},
  setIsSwiping: () => {},
});
