import React from "react"
import MetaTags from 'react-meta-tags'

const NotFound = () => {
    return (
        <div className="static-page">
            <MetaTags>
                <title>404 | Disbate</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Contacts | Disbate" />
                <meta property="og:image" content="../img/disbate-logo.png" />
            </MetaTags>
            <div className="row">
                <div className="col">
                <div className="static-header">
                    <h1>404</h1>
                </div>
                <div className="static-container">
                    <p>
                        Unfortunately, this page not found
                    </p>
                </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound