import React from 'react'
import { useSelector } from 'react-redux'

import avatar from '../img/avatar.png'
import cup from '../img/cup.png'

const Participants = ({topicData, index, clickHandler, winId, participantsPerGame}) => {

    // current topic
    const currentTopicData = useSelector(state => state.data.currentTopicData)

    // console.log("currentTopicData", currentTopicData)
    
    return (
        <div className="avatarRow">
            { topicData[index].participants.slice(0, participantsPerGame).map((item, index) => (
                <a className="avatarContainer" key={index} target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                    <button className="btn avatarItem" type="button" onClick={clickHandler}>
                        <img 
                            src={item.avatar["160x160"] !=="" ? item.avatar["160x160"] : avatar} 
                            alt="avatar" 
                            style = {index===0 ? avatarIco : {...avatarIco, position: "absolute", left: 35*index}}
                        />
                    </button>
                    { currentTopicData  && currentTopicData.participants.filter((itm, idx) => idx <=2).map((i, index) => (
                        <div key={index}>
                            { currentTopicData.type ==="dispute" && i.userScore && i.userScore === 12 && 
                                <div style={index===0 ? counter : counter}>
                                    <img src={cup} alt="cup" style={{ width: 10, height: 9, marginTop: 3, marginLeft: 0}}/>
                                </div>
                            }

                            {currentTopicData.type !=="dispute" && i.userScore &&
                                <div>
                                    {i.userScore <=9 &&
                                    //apiData finished debate
                                        <div style={i.id !== winId ? counter : {...counter, backgroundColor: "#FF2448"}}>
                                            <span className="baloonText">							
                                                {i.userScore && i.userScore}
                                            </span>
                                        </div>	
                                    }
                                    {i.userScore >9 && i.userScore <=99 &&
                                        <div style={i.id !== winId ? {...counter, width: 18, marginLeft: 32} : {...counter, width: 18, marginLeft: 32, backgroundColor: "#FF2448" }}>
                                            <span className="baloonText">								
                                                {i.userScore && i.userScore}
                                            </span>
                                        </div>	
                                    }
                                    {i.userScore >99 &&
                                        <div style={i.id !== winId ? {...counter, width: 23, marginLeft: 32} : {...counter, width: 23, marginLeft: 32, backgroundColor: "#FF2448" }}>
                                            <span className="baloonText">								
                                                {i.userScore && i.userScore}
                                            </span>
                                        </div>	
                                    }
                                </div>							
                            }
                        </div>
                    ))}
                </a>
            ))}
            <div className="avatarRate" style={{marginLeft: 40*participantsPerGame-participantsPerGame*5}}>
                <a className="rateRow" target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                    <span className="title4white">Rate</span>
                    <span className="title4gray" style={{marginLeft: 4}}>+5</span>
                </a>
            </div>
        </div>
    )
}

export default Participants


const avatarIco = {
    width: 40,
    height: 40,
    borderWidth: 1,
    boxSizing: "border-box",
    borderRadius: 20,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 300
} 

const counter = {
    width: 12,
    height: 16,
    // background: "#FF2448",
    backgroundColor: "#0A0A0A",
    borderRadius: 4,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    top: 33,
    left: 15,
    zIndex: 350
}