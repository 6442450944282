import React from "react"
import MetaTags from 'react-meta-tags'
import { translate } from '../function/translate'
import { useSelector } from 'react-redux'
import logoBetaBlue from '../img/logo-w-beta-blue.svg'
import logoAppStore from '../img/logoAppStore.svg'
import logoGP from '../img/logoGP.svg'
import ScrollButton from '../img/scrollButton.png'
import RightContentDiscover from '../img/adapted/Features_img_1@2x.png'
import RightContentCreate from '../img/adapted/Features_img_2@2x.png'
import RightContentChallange from '../img/adapted/Features_img_3@2x.png'
import RightContentMake from '../img/adapted/Features_img_4@2x.png'
import RightContentRate from '../img/adapted/Features_img_6@2x.png'
import RightContentDeliver from '../img/adapted/Features_img_7@2x.png'
import RightContentTrack from '../img/adapted/Features_img_8@2x.png'
import RightContentComm from '../img/adapted/Comm_img_8@2x.png'
import backImage from '../img/adapted/symbol_2_dibate.svg'
import { Link, Link as MainLink} from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import * as Scroll from 'react-scroll';
import { Link as ScrollLink } from 'react-scroll'

const DesktopFeatures = () => {

    document.body.style.overflow='auto'

    var Events  = Scroll.Events
    var scroller =  Scroll.scroller
    // var scroll = Scroll.animateScroll

    const [isVisible, setIsVisible] = React.useState(true)
    //  плавное скрытие
    const imageHandler= () => {
        setIsVisible(!isVisible)
    }

    React.useEffect(() => {
        Events.scrollEvent.register('begin', function(to, element) {
            // console.log('begin');
        });
      
        Events.scrollEvent.register('end', function(to, element) {
            // console.log('end', arguments);
            setTimeout(() => {
                setIsVisible(true)
            }, 100)
            // console.log('end');
        });
    }, [])
    const [winPosition, setWinPosition] = React.useState(0)
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [windowHeigth, setWindowHeigth] = React.useState(undefined)
    const ref = React.useRef()

    const language = useSelector(state => state.UI.language)

    let pageArray = [
        {title0: `Features`, title01: "Discover", title02: "exciting challenges", title2: `If you are a dispute lover, this is the place where you can find various topics, different points of view, arguments, and diverse people fighting with each other, proving their point.`, src: RightContentDiscover, angle: 0},
        {title0: "", title01: "Create new", title02: "or join existing", title2: `Ready to engage in a mental fight? Create a public dispute or debate on the subject of your choice and prove your point of view to the world. 
        `, src: RightContentCreate, angle: 30},
        {title0: "", title01: "Challange", title02: "your opponents", title2: `You can either challenge a specific person for a public dispute or debate or find an opponent from the crowd. The world has many intellectuals waiting for challenges.
        `, src: RightContentChallange, angle: 95},
        {title0: "", title01: "Make", title02: "like-minded friends", title2: `Find like-minded friends sharing your values while proving your point of view in the disputes. Then, unite and engage in collective intellectual battles to destroy opposing views by even more convincing arguments and facts.
        `, src: RightContentMake, angle: 150},
        {title0: "", title01: "Rate,", title02: "popular conflicts", title2: `The Judges and the People evaluate disputes and debates. The Judges rationally score every argument, select the official winner and issue the final verdict. The People rate every dispute and debate. People's opinion sometimes is different from Judges because they represent the moral compass of the crowds and evaluate the case from a different angle.`, src: RightContentRate, angle: -174},
        {title0: "", title01: "Deliver", title02: "justice as Judge", title2: `Judges are users of the App, that have sufficient competencies to evaluate the weight of arguments, check presented facts, and deliver final verdict impartially and fairly. Judges that provide justice are appointed randomly or invited personally, depending on the case.
        `, src: RightContentDeliver, angle: -30},
        {title0: "", title01: "Talk", title02: "or  leave comments", title2: `Have something to say to your opponents or like-minded friends in private? Feel free to chat real-time inside the App. Or use public text and video comments to express your thoughts with the entire crowd looking at you.
        `, src: RightContentComm, angle: -140},
        {title0: "", title01: "Track", title02: "your progress", title2: `You can track your disputes and debates results, people that follow, rate, or message you all in one place.`, src: RightContentTrack, angle: -105}
    ]

    let anhors = [
        {id: 0, title: "Discover"},
        {id: 1, title: "Create"},
        {id: 2, title: "Challange"},
        {id: 3, title: "Make"},
        {id: 4, title: "Rate"},
        {id: 5, title: "Deliver"},
        {id: 6, title: "Talk"},
        {id: 7, title: "Track"}
    ]

    React.useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
        return (() => {
            window.removeEventListener('scroll',listenToScroll)
        })
    })

    React.useEffect(() => {
        function handleResize() {
            setWindowHeigth(window.innerHeight)
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const [customStyle, setCustomStyle] = React.useState({position: "relative"})

    React.useEffect(() => {
        // console.log("winPosition", winPosition)
        if( windowHeigth ) {
            if(winPosition<windowHeigth*0.86) {
                setCurrentIndex(0)
            } else if(winPosition>=windowHeigth*0.86 && winPosition<windowHeigth*0.86*2) {
                setCurrentIndex(1)
            } else if(winPosition>=windowHeigth*0.86*2 && winPosition<windowHeigth*0.86*3) {
                setCurrentIndex(2)
            } else if(winPosition>=windowHeigth*0.86*3 && winPosition<windowHeigth*0.86*4) {
                setCurrentIndex(3)
            } else if(winPosition>=windowHeigth*0.86*4 && winPosition<windowHeigth*0.86*5) {
                setCurrentIndex(4)
            } else if(winPosition>=windowHeigth*0.86*5 && winPosition<windowHeigth*0.86*5.8) {
                setCurrentIndex(5)
            } else if(winPosition>=windowHeigth*0.86*5.9 && winPosition<windowHeigth*0.86*6.8) {
                setCurrentIndex(6)
            }
            else if(winPosition>=windowHeigth*0.86*6.8) {
                setCurrentIndex(7)
            }
        }
    }, [winPosition, currentIndex, windowHeigth])

    // текущая позиция

    const listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        
        if(winScroll>72) {
            setCustomStyle({position: "fixed", top: 0})
        } else {
            setCustomStyle({position: "relative"})
        }

        setWinPosition(Math.round(winScroll))
    }

    const goToStoreButton = (param) => {
        // TODO
        console.log("goToStoreButton")
    }

    return (
        <div className="static-white-page">
            <MetaTags>
                <title>{translate("Features", language)} | Disbate</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Disbate" />
                <meta property="og:image" content="../../public/logo.png" />
            </MetaTags>
            
            <div className="row">
                <div className="col-md-12">
                <div className="static-white-page_header">
                    <MainLink to="/">
                        <img src={logoBetaBlue} alt="disbate" className="static-white-page_logo"/>
                    </MainLink>
                    <div>
                        <MainLink to="/aboutus" className="title2" style={{color: "#7A8599", textDecoration: 'none'}}>{translate("About us", language)}</MainLink>
                        <MainLink to="/ourmission" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Our mission", language)}</MainLink>
                        <MainLink to="/features" className="title2" style={{color: "#000", marginLeft: 48, textDecoration: 'none'}}>{translate("Features", language)}</MainLink>
                        <MainLink to="/contact" className="title2" style={{color: "#7A8599", marginLeft: 48, textDecoration: 'none'}}>{translate("Contacts", language)}</MainLink>
                        <div style={{
                                position: "absolute",
                                top: 69,
                                left: 961,
                                width: 69,
                                height: 2,
                                background: "#2D69E0"
                            }}>
                        </div>
                    </div>
                </div>
                
                <div className="anchorMenu" style={customStyle}>
                    {anhors.map((item, index) => (
                        <ScrollLink 
                            className="anchorMenuItem" 
                            key={index} 
                            onClick={() => setIsVisible(false)} 
                            to={`section${index}`}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            activeClass="active"
                            style={index === currentIndex ? {background: "#2D69E0"} : {background: "#fff"}}
                            ref={ref}
                        >
                            <span className="title2" style={index === currentIndex ? {color: "#fff"} : {color: "#7A8599"}}>{item.title}</span>
                        </ScrollLink>
                    ))} 
                </div>
                <div className="static-white-sub_page_container">
                    {pageArray.map((item, index) => (
                        <div className="static-white-page_container" key={index} id={`section${index}`} style={index===5 ? {marginBottom: 0}: {marginBottom: 72}}>
                            <div className="static-white-page_block">
                                <div>
                                    {item.title0 !=="" ?
                                        <>
                                        <span className="title0" style={{color: "#000000"}}>{item.title0}</span>
                                        <br/>
                                        </>
                                        : null
                                    }
                                    <span className="title0" style={{color: "#2D69E0"}}>{item.title01}</span><br/>
                                    <span className="title0" style={{color: "#2D69E0"}}>{item.title02}</span>
                                </div>
                                <div style={{marginTop: 16}}>
                                    <span className="title2" style={{color: "#000000", fontWeight: "500"}}>{item.title2}</span>
                                </div>
                                {index===0 ?
                                    <div style={{marginTop: 106}}>
                                        <img src={ScrollButton} alt="Disbate" style={{height: 107, width: 96}}/>
                                    </div>
                                    : null
                                } 
                            </div>
                        </div>
                    ))}

                    {pageArray && pageArray[currentIndex] ?
                        <CSSTransition in={isVisible} timeout={500} classNames="animate">
                            <div 
                            className="fixedRightBlock" 
                            style={isVisible ? {opacity: 1} : {opacity: 0}}
                            >
                               <div style={{position: "relative", width: 536}}>
                                    <img src={pageArray[currentIndex].src} alt="disbate" style={{height: 535, width: 496, position: "fixed", zIndex: 20}}/>  
                                    <img src={backImage} alt="disbate" style={{height: 361, width: 496, marginTop: 87, transform: `rotate(${pageArray[currentIndex].angle}deg)`}}/>
                               </div>
                            </div>
                        </CSSTransition>
                        : null
                    }
                </div>
                <div className="static-white-page_footer">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <span><Link  className="title4gray" to="/privacy">Our Privacy Policy</Link> or <Link className="title4gray" to="/terms">Our Terms Of Use</Link></span>
                        <span className="title4gray" style={{marginTop: 4}}>© 2021 Disbate</span>
                    </div>

                    <div>
                        <a style={{borderWidth: 0, background: "#fff"}} target="blank" href="https://apps.apple.com/us/app/disbate/id1587363524">
                            <img src={logoAppStore} alt="disbate" className="static-white-page_logo_bottom"/>
                        </a>
                        <img src={logoGP} alt="disbate" className="static-white-page_logo_bottom" style={{width: 116, marginLeft: 12, opacity: .5}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default DesktopFeatures
