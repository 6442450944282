// discoverData
export const getTopicList = () => {
    let apiUrl = `https://api.disbate.com/v1/list/discover/recommended/all`
    const topicData =  fetch(apiUrl, {
        method: "get"       
    })
    .then(res => res.json())
    .then(data => {   
        return data
    })
    .catch(err => {
        console.log(err)      
    })
    return topicData
}
