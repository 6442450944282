import * as React from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { PlayerContext } from "../../MobileHomepage/PlayerContext";
import styles from "./styles.module.scss";

interface Props {
  children: JSX.Element[];
  activeIndex: number;
  isActive: boolean;
  onSelect(index: number): void;
}

export const HorizontalSlider = ({
  children,
  activeIndex: propsActiveIndex,
  isActive,
  onSelect,
}: Props) => {
  const playerContext = React.useContext(PlayerContext);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const totalWidth = containerRef.current?.clientWidth || 0;
  const count = children.length;
  const width = totalWidth / count;
  const activeIndex = isActive ? propsActiveIndex : 0;
  const startDragData = React.useRef<{ x: 0; y: 0 }>({ x: 0, y: 0 });
  const endDragData = React.useRef<{ x: 0; y: 0 }>({ x: 0, y: 0 });

  const onDragEnd = React.useCallback(
    (event: DraggableEvent, data: DraggableData) => {
      if (event.type === "touchend") {
        const deltaX = Math.abs(
          startDragData.current.x - endDragData.current.x
        );
        const deltaY = Math.abs(
          startDragData.current.y - endDragData.current.y
        );

        if (deltaX < 10 && deltaY < 10) {
          event.target?.dispatchEvent(
            new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            })
          );
          playerContext.setIsSwiping(false);
        }
      }

      const delta = data.lastX + activeIndex * width;
      const deltaTreshold = width / 5;

      const target = event.currentTarget;

      if (Math.abs(delta) < deltaTreshold) {
        const onTransitionEnd = () => {
          target?.removeEventListener("transitionend", onTransitionEnd);
        };

        target?.addEventListener("transitionend", onTransitionEnd);
        playerContext.setIsSwiping(false);
        return;
      }

      let next: number;
      if (delta > 0) {
        next = Math.max(activeIndex - 1, 0);
      } else {
        next = Math.min(activeIndex + 1, count - 1);
      }

      const onTransitionEnd = () => {
        target?.removeEventListener("transitionend", onTransitionEnd);
        playerContext.setIsSwiping(false);
      };

      target?.addEventListener("transitionend", onTransitionEnd);
      onSelect(next);
    },
    [onSelect, activeIndex, count, startDragData, playerContext]
  );

  const onDragStart = React.useCallback(
    (event, data) => {
      startDragData.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
      endDragData.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
      playerContext.setIsSwiping(true);
    },
    [playerContext, startDragData]
  );

  const onDragMove = React.useCallback(
    (event, data) => {
      endDragData.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
    },
    [startDragData]
  );

  return (
    <>
      <div className={styles.horizontalCell} data-index={activeIndex}>
        {/* @ts-ignore */}
        <Draggable
          axis="x"
          position={{ x: activeIndex * width * -1, y: 0 }}
          defaultClassNameDragging={styles.horizontalRowDragging}
          onStop={onDragEnd}
          onStart={onDragStart}
          onDrag={onDragMove}
          bounds={{ left: width * (count - 1) * -1, right: 0 }}
        >
          <div
            ref={containerRef}
            className={styles.horizontalRow}
            style={{ width: `${children.length * 100}%` }}
          >
            {children}
          </div>
        </Draggable>
      </div>
    </>
  );
};
