import * as React from "react";
import { useSelector } from "react-redux";
import { translate } from "../function/translate";
// @ts-ignore
import MetaTags from "react-meta-tags";

export const HomePageMeta = () => {
  // @ts-ignore
  const language = useSelector((state) => state.UI.language);

  return (
    <MetaTags>
      <title>"Disbate.com - {translate("we_solve_conflicts", language)}"</title>
      <meta name="description" content="" />
      <meta property="og:title" content="Disbate" />
      <meta property="og:image" content="../../public/logo.png" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
    </MetaTags>
  );
};
